import {Bars3Icon} from '@heroicons/react/24/outline'
import {Dialog} from '@headlessui/react'
import {useState} from 'react'
import {XMarkIcon} from '@heroicons/react/24/outline'

const navigation = [
    {name: 'Intro', href: '/docs/intro'},
    {name: 'Get Started', href: '/docs/get-started'},
    {name: 'Demo', href: '/#demo'},
    {name: 'Downloads', href: '/#downloads'},
    {name: 'Documentation', href: '/docs'},
    {name: 'Screenshots', href: 'https://kt2l.org/docs/screenshots'},
    {name: 'Disclaimer', href: '/#disclaimer'},
    {name: 'License', href: '/#license'},
//    {name: 'Contribute', href: '/#contribute'},
]

export default function PageHeader() {

    const [MobileMenuOpen, SetMobileMenuOpen] = useState(false)

    return (
        <header className="absolute inset-x-0 top-0 z-50">
            <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                <div className="flex lg:flex-1">
                    <a href="#top" className="-m-1.5 p-1.5">
                        <span className="sr-only">KT2L</span>
                    </a>
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        onClick={() => SetMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
                    </button>
                </div>
                <div className="hidden lg:flex lg:gap-x-12">
                    {navigation.map((item) => (
                        <a key={item.name} href={item.href}
                           className="text-sm font-semibold leading-6 text-gray-900">
                            {item.name}
                        </a>
                    ))}
                </div>
                <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                    <a href="#top" className="text-sm font-semibold leading-6 text-gray-900">

                    </a>
                </div>
            </nav>
      <Dialog as="div" className="lg:hidden" open={MobileMenuOpen} onClose={SetMobileMenuOpen}>
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">KT2L</span>
            </a>
            <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => SetMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                    <a
                        key={item.name}
                        href={item.href}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                ))}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
        </header>

    );
}